<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <TimeList
            :headers="headersTime"
            :items="itemsTime"
            data_table_top="true"
            data_table_footer="true"
            @initialize="initialize"
            :search_tool="true"
            :sort_desc="false"
          ></TimeList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import TimeList from "@/views/pages/time/TimeList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    TimeList,
  },

  data: () => ({
    uri: "timelog",
    headersTime: [
      { text: "ID", value: "id", sortable: true },
      { text: "Date", value: "date", sortable: true },
      { text: "Work Order", value: "work_order", filterable: true },
      { text: "Vessel", value: "vessel", filterable: true },
      { text: "Description", value: "description", filterable: true },
      {
        text: "Handler",
        align: "start",
        value: "handler_label",
        filterable: true,
      },
      { text: "Time", value: "time", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    itemsTime: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsTime = records;
        }
      );
    },
  },

  computed: {
      ...mapGetters(["currentUser"]),
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Time" }]);
  },
};
</script>
