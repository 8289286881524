<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="id"
    :custom-sort="customDateSort"
    :sort-desc="sort_desc"
    class="elevation-1"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-if="data_table_top" v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Time List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []"
                          >Clear</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!-- FILTER -->
                    <v-autocomplete
                      placeholder="Select Handler"
                      :items="handlerList"
                      item-text="name"
                      item-value="id"
                      label="Handler"
                      v-model="filterHandler"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!--<v-autocomplete
                      :items="filters"
                      v-model="filters.text"
                      label="Filters"
                      :clearable="true"
                    ></v-autocomplete>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()"
                >Clear Filters</v-btn
              >
              <v-btn color="blue darken-1" text @click="dialogFilters = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="filterItems()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Filtros -->

        <!--  Print -->
        <template>
          <v-btn
            class="ml-4"
            color="blue-grey"
            fab
            small
            dark
            @click="printItems()"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <!-- Fin Print -->

        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          max-width="750px"
          @keydown.enter="save(items)"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="formNewItem()"
              >New Time</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                      @change="getWOData()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="current_wo_vessel"
                      label="Vessel"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="current_wo_date"
                      label="WO Date"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="current_wo_port"
                      label="Port"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="current_wo_description"
                      label="Description"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-6">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.time"
                      label="Time"
                      type="number"
                      suffix="mins"
                      min="10"
                      step="10"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      :search-input.sync="editedItem.handler_label"
                      placeholder="Select Handler"
                      :items="handlerList"
                      item-text="name"
                      item-value="id"
                      label="Handler"
                      v-model="editedItem.handler"
                      :disabled="blockHanlderList"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      tabindex="-1"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          :placeholder="today"
                          readonly
                          v-on="on"
                          tabindex="-1"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      autocomplete="Description"
                      label="Description"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" tabindex="-1"
                >Cancel</v-btn
              >
              <v-btn 
                  color="blue darken-1" 
                  text 
                  @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        v-b-tooltip.hover
        title="Edit"
        >mdi-pencil</v-icon
      >
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-3">
                <v-container fluid>
                  <h5>WO Date:</h5>
                  <p>
                    {{ item.wo_date }}
                  </p>
                </v-container>
              </div>
              <div class="col-3">
                <v-container fluid>
                  <h5>Port:</h5>
                  <p>
                    {{ item.wo_port }}
                  </p>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template v-if="data_table_footer" slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
            <v-chip color="lighten-1">
              Time: &nbsp;
              <strong class="ml-2">{{ totalTime }} min.</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from 'moment';


export default {
  data: () => ({
    uri: "timelog",
    workOrdersList: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    search: "",
    expanded: [],
    singleExpand: false,
    switch1: true,
    totalTime: null,
    popDatepick1: false,
    popDatepick2: false,
    editedIndex: -1,
    handlerList: [],
    filterHandlerList: "",
    items: [],
    editedItem: {
      id: "",
      work_order: "",
      vessel: "",
      wo_date: "",
      wo_port: "",
      description: "",
      handler: "",
      handler_label: "",
      date: new Date().toISOString().substr(0, 10),
      time: 10,
    },
    defaultItem: {
      id: "",
      work_order: "",
      vessel: "",
      wo_date: "2020-06-10",
      wo_port: "ISLAND SOUTH",
      description: "",
      handler: "",
      handler_label: "",
      date: new Date().toISOString().substr(0, 10),
      time: 10,
    },
    blockHanlderList: false,

    /* Filtros & Loading...*/
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 1 },
      { text: "Vessel", value: 2 },
      { text: "Description", value: 3 },
      { text: "Time", value: 5 },
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    filterWorkOrder: "",
    filterHandler: "",
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    filteredItemList: []
  }),

  methods: {
      
      customDateSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0]==='date') {
            var aDate = moment(a[index], "DD/MM/YYYY").format()
            var bDate = moment(b[index], "DD/MM/YYYY").format()

            if (!isDesc[0]) {
                return new Date(bDate) - new Date(aDate);
            } else {
                return new Date(aDate) - new Date(bDate);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    
    printItems() {
      var ids = "";

      this.filteredItemList.forEach((filteredItem) => {
        ids += filteredItem.id + "z";
      });

      ids = ids.substring(0, ids.length - 1);

      //window.open('http://localhost/simonsen_api/reports/pdf/timelog/'+ids+'/', '_blank');
      //window.open('http://66.97.33.159/api/reports/pdf/timelog/'+ids+'/', '_blank');
      window.open("http://crm.simonsen.com.ar/api/reports/pdf/timelog/" + ids + "/","_blank");
    },

    getWOData() {
      var _self = this;

      _self.editedItem.work_order = _self.current_wo_id;

      genericService.getRecord(
        "workorders",
        this.current_wo_id,
        function (record) {
          _self.current_wo_vessel = record.vessel;
          _self.current_wo_date = record.date;
          _self.current_wo_port = record.port_label;
          _self.current_wo_description = record.description;
        }
      );
    },

    editItem(item) {
      this.formNewtItem = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item, list) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          });
        }
      });
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;

      if (
        _self.editedItem.work_order == "" ||
        _self.editedItem.description == "" ||
        _self.editedItem.handler == "" ||
        _self.editedItem.handler == undefined ||
        _self.editedItem.date == "" ||
        _self.editedItem.time == ""
      ) {
        Swal.fire({
          title: "",
          text: "All fields are mandatories.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      this.editedItem.time = this.toNumber(this.editedItem.time);

      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord(
          "/" + this.uri + "/" + this.editedItem.id,
          this.editedItem,
          function () {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        );
      } else {
        this.editedItem.work_order = this.current_wo_id;
        this.editedItem.vessel = this.current_wo_vessel;
        this.editedItem.wo_date = this.current_wo_date;
        this.editedItem.wo_port = this.current_wo_port;


        if (
          this.editedItem.work_order == "" ||
          this.editedItem.work_order == null ||
          this.editedItem.work_order == undefined ||
          this.editedItem.vessel == "" ||
          this.editedItem.vessel == null ||
          this.editedItem.vessel == undefined
        ) {
          return;
        }

        items.push(this.editedItem);
        genericService.createRecord(
          "/" + this.uri,
          this.editedItem,
          function (rta) {
            if (rta != undefined) {
              Swal.fire({
                title: "",
                text: "Record has been successfully saved!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              _self.editedItem.id = rta.id;
            }
          }
        );
      }
      this.currentItems(items);
      this.close();
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    /* Loader List */
    currentItems(items) {
      this.filteredItemList = items;

      this.loading = true;
      setTimeout(() => {
        var sum = items.reduce(
          (a, item) => a + this.formatAmount(parseInt(item["time"] || 0)),
          0
        );
        this.totalTime = sum;

        /* Filtros */
        this.itemsFiltered = items.length;
        this.loading = false;
      }, 300);
    },

    formatAmount(nr) {
      if (nr == undefined || nr == null || isNaN(nr)) {
        return 0;
      }
      return nr;
    },

    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      _self.filterHandlerList = "";

      genericService.getRecordListWithLimit(
        "/" + this.uri,
        10,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.items = records;
          _self.dialogFilters = false;
        }
      );
    },

    filterItems() {
      var _self = this;
      var params = {
        module: _self.uri,
        range: _self.rangeDates,
        wo: _self.filterWorkOrder,
        handler: _self.filterHandler,
      };

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function (records) {
        _self.items = records;
        _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },
    /* EOM - Filtros */

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },

    formNewItem() {
      var _self = this;

      if (
        _self.current_wo_handler != "" &&
        _self.current_wo_handler != undefined
      ) {
        _self.editedItem.handler = _self.current_wo_handler;
        _self.editedItem.work_order = _self.current_wo_id;
      }

      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 50);
    },
  },

  mounted() {
    var _self = this;

    if (this.$route.params.id == "new") {
      setTimeout(() => {
        this.dialog = true;
        this.formNewItem();
      }, 700);
    } else {
      if (this.search_tool == true) {
        this.focusField(this.$refs.search);
      }
    }

    genericService.getRecordList(
      "/list/users/" + this.currentUser.role + "/handler",
      function (records) {
        _self.handlerList = records;

        if (_self.currentUser.role == "Handler") {
          _self.editedItem.handler = _self.currentUser.id;
          _self.blockHanlderList = false;
        }
      }
    );

    genericService.getRecordList("/workorders/", function (records) {
      _self.workOrdersList = records.reverse();
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Time" : "Edit Time";
    },
    formTitle2() {
      return this.editedIndex === -1 ? "Start / End Time" : "Edit Time";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },

    ...mapGetters(["currentUser"]),
  },

  props: [
    "headers",
    "items",
    "data_table_top",
    "data_table_footer",
    "search_tool",
    "current_wo_id",
    "current_wo_vessel",
    "current_wo_date",
    "current_wo_port",
    "current_wo_description",
    "current_wo_handler",
    "sort_desc"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },
};
</script>
